<template>

  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <b-card>
      <!-- Header: company Info -->
      <div class="d-flex mb-1">
        <feather-icon
          icon="UploadIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Importeren van facturen
        </h4>
      </div>
      <b-row>
        <b-col cols="12">
          <b-card-text>
            Met de importer kunt u facturen importeren. Het import bestand wordt alleen geaccepteerd indien het voldoet
            aan de opzet van het voorbeeld bestand (<a href="https://payrite.nl/wp-content/uploads/2021/08/payrite_import_template.xlsx" download>Download format</a>).
          </b-card-text>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mt-2"
        >
          <b-form-file
            v-model="file"
            accept=".xlsx"
            placeholder="Kies een bestand of sleep het het bestand hierheen..."
            drop-placeholder="Sleep hier de file naartoe..."
            @change="uploadFile"
          />
        </b-col>
        <b-col
          v-if="showUploadLoader"
          cols="12" md="6"
          class="mt-2"
        >
          <div class="alert-bod" style="margin-top: 10px">
            <b-spinner small></b-spinner>
            <span><b>  Aanmaken import</b> in behandeling</span>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="refresh()"
              >
                <span class="text-nowrap">Refresh</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refImportListTable"
        class="position-relative"
        :items="fetchImports"
        responsive
        :fields="tableImportColumns"
        primary-key="id"
        show-empty
        empty-text="Geen overeenkomende records gevonden"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold"
          >
            #{{ data.value }}
          </b-link>
        </template>
        <template #cell(type)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveType(data.value) }}</span>
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ resolveStatus(data.value) }}</span>
          </div>
        </template>
        <template #cell(periodFrom)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDate(data.value) }}</span>
          </div>
        </template>
        <template #cell(periodTo)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDate(data.value) }}</span>
          </div>
        </template>
        <template #cell(timestamp)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ formatDateTime(data.value) }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link
              v-if="data.item.status !== 4 && data.item.status !== 5"
            >
              <b-spinner
                class="cursor-pointer mx-1"
                small
              />
            </b-link>
            <b-link
              v-if="data.item.status === 4 && data.item.fileURL"
              :href="data.item.fileURL"
              target="_blank"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-download-icon`"
                class="cursor-pointer mx-1"
                icon="DownloadIcon"
                size="16"
              />
              <b-tooltip
                :target="`invoice-row-${data.item.id}-download-icon`"
                placement="left"
              >
                <p class="mb-0">
                  Downloaden
                </p>
              </b-tooltip>
            </b-link>
            <b-link
              v-if="data.item.status === 4 && data.item.outputFileURL"
              :href="data.item.outputFileURL"
              target="_blank"
            >
              <feather-icon
                :id="`invoice-row-${data.item.id}-download-error-icon`"
                class="cursor-pointer"
                icon="AlertCircleIcon"
                size="16"
                style="color: #ea5455;"
              />
              <b-tooltip
                :target="`invoice-row-${data.item.id}-download-error-icon`"
                placement="left"
              >
                <p class="mb-0">
                  Download errors
                </p>
              </b-tooltip>
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalImport"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BFormFile,
  BLink,
  BOverlay,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import { onUnmounted } from '@vue/composition-api'
import useImportList from '@/views/pages/import/useImportList'
import formValidation from '@core/comp-functions/forms/form-validation'
import { formatDate, formatDateTime } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import importStoreModule from '@/views/pages/import/importStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BLink,
    BPagination,
    BOverlay,
    BButton,
    BTooltip,
    BSpinner,
    vSelect,
    BFormFile,
    BCardText,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: null,
      showUploadLoader: false,
    }
  },
  destroyed() {
    clearInterval(this.autoRefresh)
  },
  methods: {
    refresh() {
      this.refetchData()
    },
    autoRefresh() {
      setInterval(() => {
        this.refetchData()
      }, 60000)
    },
    uploadFile(e) {
      this.showUploadLoader = true
      e.stopPropagation()
      e.preventDefault()
      // const userData = JSON.parse(localStorage.getItem('userData'))
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => {
          const data = new Uint8Array(reader.result)
          const arr = []
          for (let i = 0; i !== data.length; i += 1) arr[i] = String.fromCharCode(data[i])
          const bstr = arr.join('')
          const b64 = x => window.btoa(x)
          const url = `data:application/vnd.ms-excel;base64,${b64(bstr)}`

          const fileUploadData = {
            file: url,
            relationModel: 'Import',
            name: e.target.files[0].name,
            // relationID: userData.id,
          }

          store.dispatch('app-data-import/uploadFile', fileUploadData)
            .then(file => {
              store.dispatch('app-data-import/createImport', { fileID: file.data.fileID })
                .then(() => {
                  this.autoRefresh()
                  this.file = null
                  this.showUploadLoader = false
                })
                .catch(() => {
                  this.file = null
                  this.showUploadLoader = false
                  this.$swal({
                    icon: 'error',
                    title: 'Importen',
                    text: 'Er is een fout opgetreden bij het importeren van het bestand. Probeer later het nog een keer.',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
                })
            })
            .catch(() => {
              this.file = null
              this.showUploadLoader = false
              this.$swal({
                icon: 'error',
                title: 'Importeren',
                text: 'Er is een fout opgetreden bij het importeren van het bestand. Probeer later het nog een keer.',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            })
        },
        false,
      )

      reader.readAsArrayBuffer(e.target.files[0])
    },
  },
  setup() {
    const IMPORT_APP_STORE_MODULE_NAME = 'app-data-import'

    // Register module
    if (!store.hasModule(IMPORT_APP_STORE_MODULE_NAME)) store.registerModule(IMPORT_APP_STORE_MODULE_NAME, importStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(IMPORT_APP_STORE_MODULE_NAME)) store.unregisterModule(IMPORT_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      fetchImports,
      tableImportColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refImportListTable,
      refetchData,
      showOverlay,
      resolveStatus,
      totalImport,
      resolveType,
    } = useImportList()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      fetchImports,
      tableImportColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      refImportListTable,
      refetchData,
      showOverlay,
      resolveStatus,
      totalImport,
      resolveType,
      formatDate,
      formatDateTime,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
